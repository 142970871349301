import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { FaUpload } from 'react-icons/fa'
import { FiInfo } from 'react-icons/fi'
import { AuthContext } from '../../utils/AuthContext'
import { hostNameBack, states } from '../../utils/constants'
import countriesData from '../../utils/countries.json'
import Loader from '../../utils/Loader'
import NotAuthorizedError from '../../components/common/NotAuthorizedError'
import OptionsDropdown from '../../components/common/OptionsDropdown'
import ImageCropModal from '../../components/common/ImageCropModel'
import languageOptions from "../../utils/languages.json";

function BecomeSeller({ sellerId }) {
  const { isLogin, fetchUserData } = useContext(AuthContext)
  const navigate = useNavigate()
  const [previewImage, setPreviewImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [detailsFetched, setDetailsFetched] = useState(!sellerId)
  const [cropModalOpen, setCropModalOpen] = useState(false)
  const [tempImage, setTempImage] = useState(null)

  const [sellerDetails, setSellerDetails] = useState({
    profileImage: '',
    firstName: '',
    lastName: '',
    companyName: '',
    displayName: '',
    country: '',
    state: '',
    description: '',
    languages: []
  })

  const countryOptions = countriesData.map((c) => ({
    value: c.name,
    label: c.name
  }))
  const stateOptions = states.map((s) => ({ value: s.name, label: s.name }))

  useEffect(() => {
    if (sellerId) {
      setDetailsFetched(false)
      const token = localStorage.getItem('token')
      axios
        .get(`${hostNameBack}/api/v1/sellers/seller/${sellerId}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          if (response.data.success) {
            const seller = response.data.seller
            setPreviewImage(seller.profileImage)
            setSellerDetails({
              profileImage: seller.profileImage,
              firstName: seller.fullName.split(' ')[0] || '',
              lastName: seller.fullName.split(' ')[1] || '',
              companyName: seller.companyName,
              displayName: seller.displayName,
              country: seller.country,
              description: seller.description,
              state: seller.state,
              languages: (seller.languages || []).map((lang) => ({
                label: lang,
                value: lang
              }))
            })
          } else {
            enqueueSnackbar('Something went wrong!', { variant: 'error' })
          }
        })
        .catch((e) => {
          enqueueSnackbar(e.response?.data?.error || 'Something went wrong!', {
            variant: 'error'
          })
        })
        .finally(() => {
          setDetailsFetched(true)
        })
    }
  }, [sellerId])

  // Only triggers the system file browser
  const handleSelectFileClick = () => {
    // no extra logic here — label + input handle it
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onloadend = () => {
      const img = new Image()
      img.onload = () => {
        if (img.width !== img.height) {
          setTempImage(reader.result)
          setCropModalOpen(file.name)
        } else {
          setPreviewImage(reader.result)
          setSellerDetails((prev) => ({ ...prev, profileImage: file }))
        }
      }
      img.src = reader.result
    }
    reader.readAsDataURL(file)
    e.target.value = null
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setSellerDetails((prev) => ({ ...prev, [name]: value }))
  }

  const handleCountrySelect = (selected) => {
    setSellerDetails((prev) => ({
      ...prev,
      country: selected ? selected.value : '',
      state: ''
    }))
  }

  const handleStateSelect = (selected) => {
    setSellerDetails((prev) => ({
      ...prev,
      state: selected ? selected.value : ''
    }))
  }

  const handleLanguageSelect = (selected) => {
    setSellerDetails((prev) => ({
      ...prev,
      languages: selected || []
    }))
  }

  // Crop Modal handlers
  const handleCropDone = (file) => {
    setCropModalOpen(null)
    setPreviewImage(URL.createObjectURL(file))
    setSellerDetails((prev) => ({
      ...prev,
      profileImage: file
    }))
  }
  const handleCropCancel = () => {
    setCropModalOpen(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!previewImage || !sellerDetails.profileImage) {
      enqueueSnackbar('Profile Image is Required!', { variant: 'warning' })
      return
    }
    if (!sellerDetails.country) {
      enqueueSnackbar('Country is Required!', { variant: 'warning' })
      return
    }
    if (!sellerDetails.state) {
      enqueueSnackbar('State/Region is Required!', { variant: 'warning' })
      return
    }
    if (!sellerDetails.languages || sellerDetails.languages.length < 1) {
      enqueueSnackbar('Add at least 1 language!', { variant: 'warning' })
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    Object.keys(sellerDetails).forEach((key) => {
      if (key === 'languages') {
        const arr = sellerDetails.languages.map((lang) => lang.value)
        formData.append(key, JSON.stringify(arr))
      } else {
        formData.append(key, sellerDetails[key])
      }
    })

    try {
      const token = localStorage.getItem('token')
      let response
      if (sellerId) {
        response = await axios.put(
          `${hostNameBack}/api/v1/sellers/seller/${sellerId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
            }
          }
        )
      } else {
        response = await axios.post(
          `${hostNameBack}/api/v1/sellers/seller/new`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
            }
          }
        )
      }
      if (response.data.message) {
        enqueueSnackbar(response.data.message, { variant: 'success' })
        await fetchUserData()
        if (!sellerId) navigate('/seller/dashboard')
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'error' })
      }
    } catch (err) {
      enqueueSnackbar(err.response?.data?.error || 'Something went wrong', {
        variant: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="becomeSellerDiv">
      <section className="section">
        <div className="becomeSellerContent">
          <section className="section">
            {!sellerId && (
              <h1 className="primaryHeading">
                Become a <span>Seller</span>
              </h1>
            )}
            {isLogin && detailsFetched ? (
              <form onSubmit={handleSubmit} className="form">
                <div className="inputDiv">
                  <label>
                    Profile Picture <span>*</span>
                  </label>
                  <div className="infoText">
                    <FiInfo className="icon" /> Recommended image of 1:1 ratio
                    - e.g. 500x500
                  </div>
                  <div className="uploadDivWrapper">
                    <label
                      className="uploadDiv"
                      htmlFor="profileImageInput"
                      onClick={handleSelectFileClick}
                    >
                      {previewImage ? (
                        <img src={previewImage} alt="Profile Preview" />
                      ) : (
                        <FaUpload className="uploadIcon" />
                      )}
                    </label>
                  </div>
                  <input
                    type="file"
                    id="profileImageInput"
                    className="uploadInput"
                    name="profileImage"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                </div>
                <div className="inputDiv">
                  <div className="inputInnerDiv">
                    <label>
                      First Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="firstName"
                      value={sellerDetails.firstName}
                      onChange={handleChange}
                      placeholder="Enter first name"
                      disabled={isLoading}
                      required
                    />
                  </div>
                  <div className="inputInnerDiv">
                    <label>
                      Last Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="lastName"
                      value={sellerDetails.lastName}
                      onChange={handleChange}
                      placeholder="Enter last name"
                      disabled={isLoading}
                      required
                    />
                  </div>
                  <div className="inputInnerDiv">
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="inputField"
                      name="companyName"
                      value={sellerDetails.companyName}
                      onChange={handleChange}
                      placeholder="Enter company name (optional)"
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="inputDiv">
                  <div className="inputInnerDiv">
                    <label>
                      Display Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="displayName"
                      value={sellerDetails.displayName}
                      onChange={handleChange}
                      placeholder="Enter name to be displayed"
                      disabled={isLoading}
                      required
                    />
                  </div>
                  <div className="inputInnerDiv">
                    <label>
                      Country <span>*</span>
                    </label>
                    <OptionsDropdown
                      options={countryOptions}
                      value={
                        countryOptions.find(
                          (c) => c.value === sellerDetails.country
                        ) || null
                      }
                      onChange={handleCountrySelect}
                      placeholder="Select Country"
                      isMulti={false}
                      isDisabled={isLoading}
                    />
                  </div>
                  {sellerDetails.country === 'Canada' ? (
                    <div className="inputInnerDiv">
                      <label>
                        State/Region <span>*</span>
                      </label>
                      <OptionsDropdown
                        options={stateOptions}
                        value={
                          stateOptions.find(
                            (s) => s.value === sellerDetails.state
                          ) || null
                        }
                        onChange={handleStateSelect}
                        placeholder="Select State/Region"
                        isMulti={false}
                        isDisabled={!sellerDetails.country || isLoading}
                      />
                    </div>
                  ) : (
                    <div className="inputInnerDiv">
                      <label>
                        Province/State <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="inputField"
                        placeholder="Enter State"
                        value={sellerDetails.state}
                        name="state"
                        onChange={handleChange}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="inputDiv">
                  <label>
                    Description <span>*</span>
                  </label>
                  <textarea
                    className="inputField"
                    name="description"
                    value={sellerDetails.description}
                    onChange={handleChange}
                    placeholder="Introduce yourself"
                    disabled={isLoading}
                    required
                  />
                </div>
                <div className="inputDiv">
                  <label>
                    Languages <span>*</span>
                  </label>
                  <div className="infoText">
                    <FiInfo className="icon" /> Max 3 languages can be selected
                  </div>
                  <OptionsDropdown
                    options={languageOptions}
                    value={sellerDetails.languages}
                    onChange={handleLanguageSelect}
                    placeholder="Select Languages"
                    isMulti
                    maxSelect={3}
                    isDisabled={isLoading}
                  />
                </div>
                <button type="submit" className="primaryBtn" disabled={isLoading}>
                  {sellerId ? 'Update' : 'Continue'}
                </button>
              </form>
            ) : isLogin === false ? (
              <NotAuthorizedError
                message="Start Your Selling Journey! Login or signup to Get Started"
                actions={[
                  { name: 'Login', to: '/login' },
                  { name: 'Register', to: '/register' }
                ]}
              />
            ) : (
              <Loader type="simpleMini" />
            )}
          </section>
        </div>
      </section>
      {isLoading && <Loader type="checkmate" />}
      <ImageCropModal
        imageSrc={tempImage}
        open={cropModalOpen}
        onCancel={handleCropCancel}
        onComplete={handleCropDone}
        aspect={1}
      />
    </div>
  )
}

export default BecomeSeller
