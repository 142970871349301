import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { hostNameBack } from '../../utils/constants';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Loader from '../../utils/Loader';
import ConfirmDialog from '../../components/common/ConfirmDialog';

function AdminFAQ() {
    const [faqs, setFaqs] = useState([]);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Confirmation Dialog
    const [showDeleteConfirm, setShowDeleteConfirm] = useState({ open: false, id: null });

    const token = localStorage.getItem('adminToken');

    useEffect(() => {
        fetchAllFAQs();
    }, []);

    const fetchAllFAQs = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`${hostNameBack}/api/v1/faq/all`);
            if (data.success) {
                setFaqs(data.faqs);
            }
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddOrUpdate = async (e) => {
        e.preventDefault();
        if (!question.trim() || !answer.trim()) {
            enqueueSnackbar('Both question and answer are required', { variant: 'warning' });
            return;
        }
        setIsLoading(true);
        try {
            if (editMode) {
                const { data } = await axios.put(`${hostNameBack}/api/v1/faq/${editId}`,
                    { question, answer },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                if (data.success) {
                    enqueueSnackbar('FAQ updated successfully', { variant: 'success' });
                    resetForm();
                    fetchAllFAQs();
                }
            } else {
                const { data } = await axios.post(`${hostNameBack}/api/v1/faq/new`,
                    { question, answer },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                if (data.success) {
                    enqueueSnackbar('FAQ created successfully', { variant: 'success' });
                    resetForm();
                    fetchAllFAQs();
                }
            }
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (faq) => {
        setEditMode(true);
        setEditId(faq._id);
        setQuestion(faq.question);
        setAnswer(faq.answer);
    };

    const handleDeleteClick = (id) => {
        setShowDeleteConfirm({ open: true, id });
    };

    const handleConfirmDelete = async () => {
        setIsLoading(true);
        try {
            const id = showDeleteConfirm.id;
            const { data } = await axios.delete(`${hostNameBack}/api/v1/faq/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (data.success) {
                enqueueSnackbar('FAQ deleted successfully', { variant: 'success' });
                fetchAllFAQs();
            }
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        } finally {
            setIsLoading(false);
            setShowDeleteConfirm({ open: false, id: null });
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirm({ open: false, id: null });
    };

    const resetForm = () => {
        setQuestion('');
        setAnswer('');
        setEditMode(false);
        setEditId(null);
    };

    return (
        <>
            {/* If isLoading is true, show full-page overlay loader */}
            {isLoading && <Loader type="checkmate" />}

            <div className='adminFAQDiv'>
                <div className='adminFAQContent'>
                    <form onSubmit={handleAddOrUpdate} className='form'>
                        <h2 className='secondaryHeading'>Information Center - <span>FAQ</span></h2>
                        <div className="horizontalLine"></div>

                        <div className="inputDiv">
                            <label>Question <span>*</span></label>
                            <input
                                type='text'
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder='Enter question'
                                className='inputField'
                                required
                                disabled={isLoading}
                            />
                        </div>

                        <div className="inputDiv">
                            <label>Answer <span>*</span></label>
                            <textarea
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                rows={5}
                                placeholder='Enter answer'
                                className='inputField'
                                required
                                disabled={isLoading}
                            />
                        </div>

                        <div className="buttonsRow">
                            <button type='submit' className='primaryBtn' disabled={isLoading}>
                                {editMode ? 'Update FAQ' : 'Add FAQ'}
                            </button>
                            {editMode && (
                                <button
                                    type='button'
                                    className='secondaryBtn'
                                    onClick={resetForm}
                                    disabled={isLoading}
                                >
                                    Cancel Edit
                                </button>
                            )}
                        </div>
                    </form>

                    <div className="horizontalLine"></div>

                    {/* If we're not loading, show the FAQ list */}
                    {!isLoading && (
                        <div className='faqList'>
                            {faqs.length === 0 && (
                                <p>No FAQs have been added yet.</p>
                            )}
                            {faqs.map((faq, index) => (
                                <div key={faq._id}>
                                    <div className='faqRow'>
                                        <div className='faqQA'>
                                            <p className='question'>
                                                <strong>Q:</strong> {faq.question}
                                            </p>
                                            <p className='answer'>
                                                <strong>A:</strong> {faq.answer}
                                            </p>
                                        </div>
                                        <div className='faqActions'>
                                            <button
                                                onClick={() => handleEdit(faq)}
                                                className="editBtn"
                                                disabled={isLoading}
                                            >
                                                <FaEdit />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteClick(faq._id)}
                                                className="deleteBtn"
                                                disabled={isLoading}
                                            >
                                                <FaTrash />
                                            </button>
                                        </div>
                                    </div>
                                    {index < faqs.length - 1 && <div className="horizontalLine"></div>}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <ConfirmDialog
                    open={showDeleteConfirm.open}
                    title="Confirm Deletion"
                    message="Are you sure you want to delete this FAQ?"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                    isLoading={isLoading}
                />
            </div>
        </>
    );
}

export default AdminFAQ;
