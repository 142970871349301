import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Switch } from '@mui/material';
import { AuthContext } from '../../utils/AuthContext';
import Gallery from '../../components/seller/Gallery';
import { hostNameBack, tommorrow } from '../../utils/constants';
import Loader from "../../utils/Loader";

function CreateService() {
    let { isLogin, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();

    const [offerDiscount, setOfferDiscount] = useState(false);
    const [serviceImages, setServiceImages] = useState([]);
    const [newQuestion, setNewQuestion] = useState("");
    const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [packages, setPackages] = useState([
        {
            name: 'BASIC',
            active: true,
            title: '',
            description: '',
            price: 0,
            deliveryDays: 1,
            salesPrice: 0,
            amountToGet: 0
        },
        {
            name: 'STANDARD',
            active: true,
            title: '',
            description: '',
            price: 0,
            deliveryDays: 1,
            salesPrice: 0,
            amountToGet: 0
        },
        {
            name: 'ULTIMATE',
            active: true,
            title: '',
            description: '',
            price: 0,
            deliveryDays: 1,
            salesPrice: 0,
            amountToGet: 0
        }
    ]);

    const [serviceDetails, setServiceDetails] = useState(
        id
            ? null
            : {
                serviceImages: [],
                title: '',
                description: '',
                category: "",
                discountPercent: 0,
                discountExpiryDate: 0,
                tags: '',
                questions: []
            }
    );

    const [feesObj, setFeesObj] = useState({
        seller: { product: 0, service: 0 },
        paidSeller: { product: 0, service: 0 },
        buyer: { product: 0, service: 0 }
    });

    useEffect(() => {
        axios.get(`${hostNameBack}/api/v1/settings/admin/feesAndMembership`)
            .then(response => {
                if (response.data.success) setFeesObj(response.data.fees);
            })
            .catch(e => {
                enqueueSnackbar(e?.response?.data?.error || "Something went wrong!", { variant: "error" });
            });
    }, []);

    useEffect(() => {
        if (!id) {
            axios.get(`${hostNameBack}/api/v1/categories/service/all`)
                .then(response => {
                    if (response.data.success) {
                        let cats = response.data.categories;
                        setCategories(cats);
                        setServiceDetails(prev => ({
                            ...prev,
                            category: cats[0]?.name || ""
                        }));
                    } else {
                        enqueueSnackbar("Something went wrong!", { variant: "error" });
                    }
                })
                .catch(e => {
                    enqueueSnackbar(e?.response?.data?.error || 'Failed to fetch categories', { variant: 'error' });
                });
            return;
        }

        // Editing existing
        axios.get(`${hostNameBack}/api/v1/services/service/${id}`)
            .then(response => {
                if (response.data.success) {
                    const service = response.data.service;
                    setServiceDetails({
                        serviceImages: service.serviceImages,
                        title: service.title,
                        description: service.description,
                        category: service.category,
                        discountPercent: service.discountPercent,
                        discountExpiryDate: "",
                        tags: service.tags,
                        questions: service.questions
                    });
                    if (Number(service.discountPercent) !== 0) setOfferDiscount(true);
                    setServiceImages(service.serviceImages.map(img => `${img}`));

                    const existing = service.packages || [];
                    const updatedPackages = ['BASIC', 'STANDARD', 'ULTIMATE'].map(pkgName => {
                        const existingPkg = existing.find(ep => ep.name === pkgName);
                        if (existingPkg) {
                            return { ...existingPkg, active: true };
                        } else {
                            return {
                                name: pkgName,
                                active: false,
                                title: '',
                                description: '',
                                price: 0,
                                deliveryDays: 1,
                                salesPrice: 0,
                                amountToGet: 0
                            };
                        }
                    });
                    setPackages(updatedPackages);
                } else {
                    enqueueSnackbar("Something went wrong", { variant: "error" });
                }
            })
            .catch(e => {
                enqueueSnackbar(e.response?.data?.error || "Something went wrong", { variant: "error" });
            });

        axios.get(`${hostNameBack}/api/v1/categories/service/all`)
            .then(response => {
                if (response.data.success) {
                    setCategories(response.data.categories);
                } else {
                    enqueueSnackbar("Something went wrong!", { variant: "error" });
                }
            })
            .catch(e => {
                enqueueSnackbar(e?.response?.data?.error || 'Failed to fetch categories', { variant: 'error' });
            });
    }, [id]);

    const categoryOptions = categories.map((category, index) => (
        <option key={index} value={category.name}>{category.name}</option>
    ));

    const addedQuestions = serviceDetails?.questions?.map((question, idx) => (
        <React.Fragment key={idx}>
            <div className="reqQuestion">
                <p>{question}</p>
                <div className="icons">
                    <FaEdit className='icon' onClick={() => handleEditQuestion(idx)} />
                    <FaTrash className='icon' onClick={() => handleDeleteQuestion(idx)} />
                </div>
            </div>
            <div className="horizontalLine"></div>
        </React.Fragment>
    ));

    const handleEditQuestion = (index) => {
        setNewQuestion(serviceDetails.questions[index]);
        setEditingQuestionIndex(index);
    };

    const handleDeleteQuestion = (index) => {
        const updated = serviceDetails.questions.filter((_, i) => i !== index);
        setServiceDetails(prev => ({ ...prev, questions: updated }));
    };

    const handleAddQuestion = (e) => {
        e.preventDefault();
        if (newQuestion.trim() !== "") {
            const updated = [...serviceDetails.questions];
            if (editingQuestionIndex !== null) {
                updated[editingQuestionIndex] = newQuestion;
                setEditingQuestionIndex(null);
            } else {
                updated.push(newQuestion);
            }
            setServiceDetails(prev => ({ ...prev, questions: updated }));
            setNewQuestion("");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'discountPercent' && (value < 0 || value > 100)) return;

        setServiceDetails(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === 'discountPercent' && offerDiscount) {
            updateSalesPriceAndAmount(packages, null, name, value);
        }
    };

    const handleDiscountChange = (e) => {
        setOfferDiscount(e.target.checked);
        if (!e.target.checked) {
            setServiceDetails(prev => ({
                ...prev,
                discountPercent: 0,
                discountExpiryDate: new Date()
            }));
            updateSalesPriceAndAmount(packages, null, 'discountPercent', 0);
        }
    };

    const handlePackageChange = (index, e) => {
        const { name, value } = e.target;
        if (name === 'price' && (value < 0 || value > 1000000)) return;
        if (name === 'deliveryDays' && (value < 0 || value > 1000)) return;

        const updated = [...packages];
        updated[index][name] = value;
        if (name === 'price') {
            updateSalesPriceAndAmount(updated, index, name, value);
        } else {
            setPackages(updated);
        }
    };

    const handleTogglePackage = (index) => {
        const updated = [...packages];
        updated[index].active = !updated[index].active;
        setPackages(updated);
    };

    const updateSalesPriceAndAmount = (updatedPackages, index, name, value) => {
        let discountPercent = offerDiscount ? serviceDetails.discountPercent : 0;
        if (name === 'discountPercent') discountPercent = value;

        const commission = user?.sellerId?.sellerType === "Paid"
            ? Number(feesObj.paidSeller.service)
            : Number(feesObj.seller.service);

        updatedPackages.forEach(pkg => {
            if (pkg.active) {
                pkg.salesPrice = pkg.price - (pkg.price * (discountPercent / 100));
                pkg.amountToGet = pkg.salesPrice - (pkg.salesPrice * (commission / 100));
            }
        });
        setPackages(updatedPackages);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (serviceImages.length > 5) {
            enqueueSnackbar("Max '5' images are allowed", { variant: "warning" });
            return;
        }
        if (serviceImages.length === 0) {
            enqueueSnackbar("At least '1' gallery image is required!", { variant: "warning" });
            return;
        }
        if (serviceDetails.questions.length < 1) {
            enqueueSnackbar("At least add 1 Question!", { variant: "warning" });
            return;
        }

        // Filter out only active packages for validation + submission
        const activePackages = packages.filter(p => p.active);
        if (!activePackages.length) {
            enqueueSnackbar("At least 1 package must be active!", { variant: "warning" });
            return;
        }

        // REQUIRED checks for each active package:
        for (let obj of activePackages) {
            if (!obj.title.trim()) {
                enqueueSnackbar(`Please fill the title for the ${obj.name} package.`, { variant: "warning" });
                return;
            }
            if (!obj.description.trim()) {
                enqueueSnackbar(`Please fill the description for the ${obj.name} package.`, { variant: "warning" });
                return;
            }
            if (obj.deliveryDays < 1) {
                enqueueSnackbar(`Delivery days can't be less than 1 in the ${obj.name} package.`, { variant: "warning" });
                return;
            }
            if (obj.salesPrice < 5) {
                enqueueSnackbar(`Sale price in the ${obj.name} package cannot be less than $5.`, { variant: "warning" });
                return;
            }
        }

        setIsLoading(true);

        try {
            const formData = new FormData();
            Object.keys(serviceDetails).forEach(key => {
                if (!['serviceImages', 'questions', 'packages'].includes(key)) {
                    formData.append(key, serviceDetails[key]);
                }
            });

            serviceImages.forEach(img => {
                if (img instanceof File) formData.append('serviceImages', img);
            });
            const existing = serviceImages.filter(img => typeof img === "string");
            formData.append("existingImages", JSON.stringify(existing));

            formData.append("questions", JSON.stringify(serviceDetails.questions));
            // Only submit the active packages
            formData.append("packages", JSON.stringify(activePackages));

            const token = localStorage.getItem("token");
            if (id) {
                await axios.put(`${hostNameBack}/api/v1/services/seller/service/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });
            } else {
                await axios.post(`${hostNameBack}/api/v1/services/seller/service/new`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            enqueueSnackbar(`Post ${id ? "updated" : "created"} successfully!`, { variant: 'success' });
            navigate('/seller/postings');
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const servicePackages = packages.map((pkg, index) => {
        return (
            <React.Fragment key={pkg.name}>
                <div className="servicePackage">
                    <div className="header">
                        <h1
                            className="secondaryHeading"
                            style={
                                index === 0
                                    ? { color: "var(--danger)" }
                                    : index === 1
                                        ? { color: "var(--secondaryBlue)" }
                                        : { color: "var(--warning)" }
                            }
                        >
                            {pkg.name}
                        </h1>
                        <Switch
                            checked={pkg.active}
                            onChange={() => handleTogglePackage(index)}
                        />
                    </div>
                    <div className="horizontalLine"></div>

                    {/* If not active, we disable the fields so the user isn't forced to fill them. */}
                    <div className="form">
                        <div className="inputDiv">
                            <label>Package Title <span>*</span></label>
                            <input
                                type="text"
                                name='title'
                                className="inputField"
                                placeholder='Name your package'
                                value={pkg.title}
                                onChange={e => handlePackageChange(index, e)}
                                disabled={!pkg.active}
                            />
                        </div>
                        <div className="inputDiv">
                            <label>Package Description <span>*</span></label>
                            <textarea
                                name='description'
                                className='inputField'
                                placeholder='Describe your package'
                                value={pkg.description}
                                onChange={e => handlePackageChange(index, e)}
                                disabled={!pkg.active}
                            />
                        </div>
                        <div className="inputDiv">
                            <div className="inputInnerDiv">
                                <label>Price ($) <span>*</span></label>
                                <input
                                    type='number'
                                    name='price'
                                    className='inputField'
                                    placeholder='Price'
                                    value={pkg.price}
                                    onChange={e => handlePackageChange(index, e)}
                                    disabled={!pkg.active}
                                />
                            </div>
                            <div className="inputInnerDiv">
                                <label>Delivery Time (days) <span>*</span></label>
                                <input
                                    type='number'
                                    name='deliveryDays'
                                    className='inputField'
                                    placeholder='Enter delivery days'
                                    value={pkg.deliveryDays}
                                    onChange={e => handlePackageChange(index, e)}
                                    disabled={!pkg.active}
                                />
                            </div>
                        </div>
                        <div>Sales Price: {pkg.salesPrice}</div>
                        <div>Amount to Get: {pkg.amountToGet}</div>
                    </div>
                </div>
                {index !== 2 && <div className="verticalLine"></div>}
            </React.Fragment>
        );
    });

    return (
        <div className='createServiceDiv'>
            <section className="section">
                <div className="createServiceContent">
                    <h1 className="primaryHeading">
                        {id ? "Update" : "Add New"} <span>Post</span>
                    </h1>

                    {(isLogin && serviceDetails) ? user.role === "seller" ? (
                        <form className='form' onSubmit={handleSubmit}>
                            <div className='inputDiv'>
                                <label>Title <span>*</span></label>
                                <input
                                    type="text"
                                    className='inputField'
                                    name="title"
                                    value={serviceDetails.title}
                                    onChange={handleChange}
                                    placeholder='Enter post title'
                                    required
                                />
                            </div>

                            <div className='inputDiv'>
                                <label>Category <span>*</span></label>
                                <select
                                    name="category"
                                    className='inputField dropdownLight'
                                    value={serviceDetails.category}
                                    onChange={handleChange}
                                    required
                                >
                                    {categoryOptions}
                                </select>
                            </div>

                            <div className="servicePackagesDiv">
                                {servicePackages}
                            </div>

                            <div className='inputDiv'>
                                <div className="checkboxDiv">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id='checkbox'
                                        name="offerDiscount"
                                        checked={offerDiscount}
                                        onChange={handleDiscountChange}
                                    />
                                    <label htmlFor='checkbox'>Offer Discount</label>
                                </div>
                            </div>

                            {offerDiscount && (
                                <div className='inputDiv'>
                                    <div className='inputInnerDiv'>
                                        <label>Discount (%) <span>*</span></label>
                                        <input
                                            type="number"
                                            className='inputField'
                                            name="discountPercent"
                                            value={serviceDetails.discountPercent}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className='inputInnerDiv'>
                                        <label>Discount Expiry <span>*</span></label>
                                        <input
                                            type="date"
                                            className='inputField'
                                            name="discountExpiryDate"
                                            value={serviceDetails.discountExpiryDate}
                                            onChange={handleChange}
                                            min={tommorrow}
                                            required
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='inputDiv'>
                                <label>Description <span>*</span></label>
                                <textarea
                                    name="description"
                                    className='inputField'
                                    value={serviceDetails.description}
                                    onChange={handleChange}
                                    placeholder='Describe your service'
                                    required
                                />
                            </div>

                            <Gallery
                                images={serviceImages}
                                setImages={setServiceImages}
                                setDetails={setServiceDetails}
                                dimension="fiverrGig"
                                isCreateServiceGallery={true}
                            />

                            <div className="inputDiv">
                                <label>Requirements <span>*</span></label>
                                {serviceDetails.questions.length > 0 && (
                                    <div className="reqQuestions">{addedQuestions}</div>
                                )}
                                <div className="addReq">
                                    <input
                                        type="text"
                                        className="inputField"
                                        placeholder='Enter question'
                                        value={newQuestion}
                                        onChange={(e) => setNewQuestion(e.target.value)}
                                    />
                                    <button
                                        className='secondaryBtn'
                                        onClick={handleAddQuestion}
                                        disabled={isLoading}
                                    >
                                        {editingQuestionIndex === null ? "Add" : "Update"}
                                    </button>
                                </div>
                            </div>

                            <div className='inputDiv'>
                                <label>Tags</label>
                                <input
                                    type="text"
                                    className='inputField'
                                    name="tags"
                                    value={serviceDetails.tags}
                                    onChange={handleChange}
                                    placeholder='Enter tags'
                                />
                            </div>

                            <button
                                type="submit"
                                className='primaryBtn'
                                disabled={isLoading}
                            >
                                {id ? "Update" : "Create"} Post
                            </button>
                        </form>
                    ) : (
                        <div>You are not a seller. Please create seller account to access this page</div>
                    ) : isLogin === false ? (
                        <div>Please login to create a Post</div>
                    ) : (
                        <Loader type="simpleMini" />
                    )}
                </div>
            </section>

            {isLoading && <Loader type="checkmate" />}
        </div>
    );
}

export default CreateService;
