import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';

function AdminEmailTemplates() {
    const [previewTemplate, setPreviewTemplate] = useState(null);

    const emailTemplates = [
        {
            id: 1,
            name: "Seller Marketing Template",
            description: "Marketing template designed specifically for sellers",
            image: "https://res.cloudinary.com/drcuo3fya/image/upload/v1738997178/faithzy/uploads/mbzpz182op87teugwioa.png",
            code: `<div style="width: 100%; max-width: 900px; margin: 0 auto;">
                <a href="https://faithzy.com"><img src="https://res.cloudinary.com/drcuo3fya/image/upload/v1738997178/faithzy/uploads/mbzpz182op87teugwioa.png" alt="Start Selling" width="100%"></a>
            </div>`
        },
        {
            id: 2,
            name: "Buyers & Sellers Template",
            description: "General marketing template for all users",
            image: "https://res.cloudinary.com/drcuo3fya/image/upload/v1739076722/To_Buyers_and_Sellers_-_Email_Template_1_xxabyw.png",
            code: `<div style="width: 100%; max-width: 900px; margin: 0 auto;">
                <a href="https://faithzy.com"><img src="https://res.cloudinary.com/drcuo3fya/image/upload/v1739076722/To_Buyers_and_Sellers_-_Email_Template_1_xxabyw.png" alt="Join Now" width="100%"></a>
            </div>`
        }
    ];

    const handleDownload = async (template) => {
        try {
            const hdImageUrl = template.image.replace('/upload/', '/upload/q_auto,f_png,w_1920/');
            const response = await fetch(hdImageUrl);
            const blob = await response.blob();
            const element = document.createElement("a");
            element.href = URL.createObjectURL(blob);
            element.download = `${template.name.toLowerCase().replace(/\s+/g, '-')}.png`;
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            URL.revokeObjectURL(element.href);
            enqueueSnackbar('Template image downloaded successfully!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Failed to download template image', { variant: 'error' });
        }
    };

    const copyToClipboard = async (template) => {
        try {
            await navigator.clipboard.writeText(template.code);
            enqueueSnackbar('Template code copied to clipboard!', { variant: 'success' });
        } catch (err) {
            enqueueSnackbar('Failed to copy template code', { variant: 'error' });
        }
    };

    return (
        <div className="templatesSection">
            
            <h3 className="secondaryHeading">Email <span>Templates</span></h3>

            <div className="templatesGrid">
                {emailTemplates.map((template) => (
                    <div key={template.id} className="templateCard">
                        <div className="templateImageContainer">
                            <img
                                src={template.image}
                                alt={template.name}
                                className="templateImage"
                            />
                            <div className="templateOverlay">
                                <h4 className="templateName">{template.name}</h4>
                                <p className="templateDescription">{template.description}</p>
                                <div className="templateActions">
                                    <button
                                        className="templateBtn previewBtn"
                                        onClick={() => setPreviewTemplate(template)}
                                    >
                                        Preview
                                    </button>
                                    <button
                                        className="templateBtn copyBtn"
                                        onClick={() => copyToClipboard(template)}
                                    >
                                        Copy Code
                                    </button>
                                    <button
                                        className="templateBtn downloadBtn"
                                        onClick={() => handleDownload(template)}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {previewTemplate && (
                <div className="modalOverlay">
                    <div className="modalContent">
                        <div className="modalHeader">
                            <h3 className="secondaryHeading">{previewTemplate.name}</h3>
                            <div className="modalActions">
                                <button
                                    className="templateBtn copyBtn"
                                    onClick={() => copyToClipboard(previewTemplate)}
                                >
                                    Copy Code
                                </button>
                                <button
                                    className="modalCloseBtn"
                                    onClick={() => setPreviewTemplate(null)}
                                >
                                    ✕
                                </button>
                            </div>
                        </div>
                        <div className="modalBody">
                            <div dangerouslySetInnerHTML={{ __html: previewTemplate.code }} />
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default AdminEmailTemplates;