import React, { useContext, useState, useEffect } from 'react';
import axios from "axios";
import { useSnackbar } from "notistack";

import ProductCard from '../../components/buyer/ProductCard';
import ServiceCard from '../../components/buyer/ServiceCard';
import { AuthContext } from '../../utils/AuthContext';
import { hostNameBack } from '../../utils/constants';
import Loader from '../../utils/Loader';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";


function SampleProvisions({ pre, openedProduct, openedService, selectedType }) {
  axios.defaults.withCredentials = true;
  const { enqueueSnackbar } = useSnackbar();
  const { isLogin, isTabletPro, isTablet, isMobilePro, isMobile } = useContext(AuthContext);

  const [allProvisions, setAllProvisions] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {

    const endpoint = selectedType === 'Products' ? 'products' : 'services';
    setIsLoading(true);

    if (pre !== "recents") {

      // const dump = ()=>{
      axios.get(`${hostNameBack}/api/v1/${endpoint}/all`)
        .then((response) => setAllProvisions(response.data.allProducts || response.data.allServices))
        .catch(e => enqueueSnackbar(e.response?.data?.error || "Something went wrong!", { variant: "error" }))
        .finally(() => setIsLoading(false))
      // }
      // setTimeout(dump, 5000);
    }

    else if (pre === 'recents' && isLogin) {
      const token = localStorage.getItem('token');
      axios.get(`${hostNameBack}/api/v1/${endpoint}/user/recentlyViewed/`, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.recentlyViewed)
            selectedType === "Products" ? setRecentlyViewed(response.data.recentlyViewed.viewedProducts) : setRecentlyViewed(response.data.recentlyViewed.viewedServices)
        })
        .catch((e) => {
          enqueueSnackbar(e.response?.data?.error || "Something went wrong!", { variant: "error" });
        })
        .finally(() => {
          setIsLoading(false);
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, pre, selectedType]);

  let provisionItems = [];
  let relatedItems = [];

  const CardComponent = selectedType === 'Products' ? ProductCard : ServiceCard;

  if (pre === "recents" && recentlyViewed) {
    provisionItems = recentlyViewed.map((item, index) => (
      <CardComponent key={index} item={item} />
    ));
  }
  if (pre !== "recents") {
    provisionItems = allProvisions.map((item, index) => {
      if (pre === "discounted" && !item.status.includes("discounted"))
        return null;
      if (pre === "related" && openedProduct) {
        if (item.category === openedProduct.category && item._id !== openedProduct._id)
          relatedItems.unshift(<CardComponent key={index} item={item} />);
        else if (item._id !== openedProduct._id)
          relatedItems.push(<CardComponent key={index} item={item} />);
        return null;
      }
      else if (pre === "related" && openedService) {
        if (item.category === openedService.category && item._id !== openedService._id)
          relatedItems.unshift(<CardComponent key={index} item={item} />);
        else if (item._id !== openedService._id)
          relatedItems.push(<CardComponent key={index} item={item} />);
        return null;
      }
      return <CardComponent key={index} item={item} />;
    });
  }

  provisionItems = provisionItems.filter(value => value != null);

  if (pre === "related")
    provisionItems = [...relatedItems];

  const maxVisible = (pre === "chat" || isMobile) ? 1 : isMobilePro ? 2 : isTablet ? 3 : isTabletPro ? 4 : 5;
  const totalItems = provisionItems.length;

  const handleScrollLeft = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
    setTimeout(() => setIsAnimating(false), 300); // Match this with CSS transition duration
  };

  const handleScrollRight = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
    setTimeout(() => setIsAnimating(false), 300); // Match this with CSS transition duration
  };


  const translateX = `-${currentIndex * (100 / maxVisible)}%`;

  return (
    <div className='sampleProvisionsDiv'>
      <div className="sampleProvisionsContent">
        <div className="sampleProvisionsUpper">
          {pre === "chat" ? (
            <h1 className='secondaryHeading'><span>{selectedType}</span> you may like</h1>
          ) : (
            <h1 className="primaryHeading">
              {pre === "discounted" ? "Discounted" : pre === "related" ? "Related" : pre === "recents" ? "Recently Viewed" : "Top"}
              <span> {selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}</span>
            </h1>
          )}
          <div className='sampleProvisionsUpperRight'></div>
        </div>

        <div className="sampleProvisionsLower">
          {isLoading ? (
            <Loader type="simpleMini" />
          ) : (
            <><div className="carousel-container">

              <div className="carousel-track" style={{ transform: `translateX(${translateX})`, gridTemplateColumns: `repeat(${totalItems}, ${100 / maxVisible}%)` }}>
                {provisionItems.length > 0 ? provisionItems : "Nothing to show here..."}
              </div>

            </div>

              {totalItems > maxVisible && (
                <>
                  {currentIndex !== 0 && (
                    <div onClick={handleScrollLeft}>
                      <IoIosArrowBack className='slideLeftBtn' />
                    </div>
                  )}
                  {(totalItems - currentIndex) !== maxVisible && (
                    <div onClick={handleScrollRight}>
                      <IoIosArrowForward className="slideRightBtn" />
                    </div>
                  )}
                </>
              )}

            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SampleProvisions;