import React, { useState } from 'react'
import Select from 'react-select'
import { enqueueSnackbar } from 'notistack'

function DropdownNew({ options, value, onChange, placeholder, isMulti, maxSelect, isDisabled }) {
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const customStyles = {
        control: (base, state) => ({
            ...base,
            width: '100%',
            border: '1px solid var(--darkGray)',
            borderRadius: '8px',
            padding: '2px 8px',
            outline: 'none',
            margin: '5px 0px',
            minHeight: 'unset',
            fontSize: '0.8rem',
            boxShadow: state.isFocused ? '0 0 0 1px var(--primaryBlue)' : 'none',
            borderColor: state.isFocused ? 'var(--primaryBlue)' : 'var(--darkGray)',
            '&:hover': {
                borderColor: 'var(--darkGray)'
            }
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: '0.8rem'
        }),
        singleValue: (base) => ({
            ...base,
            color: 'var(--black)'
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: 'var(--lightGray)',
            color: 'var(--black)'
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: 'var(--black)'
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: 'var(--black)',
            ':hover': {
                backgroundColor: 'var(--gray)',
                color: 'var(--black)'
            }
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: 'var(--darkGray)'
        })
    }

    const handleChange = (selected) => {
        if (isMulti && maxSelect && selected?.length > maxSelect) {
            enqueueSnackbar(`Maximum ${maxSelect} items allowed`, { variant: 'info' })
            return
        }
        onChange(selected)
        if (isMulti && maxSelect && selected?.length === maxSelect) {
            setMenuIsOpen(false)
        }
    }

    return (
        <Select
            options={options}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            isMulti={isMulti}
            isDisabled={isDisabled}
            styles={customStyles}
            menuPlacement="auto"
            closeMenuOnSelect={!isMulti}
            menuIsOpen={!isDisabled && menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
        />
    )
}

export default DropdownNew