import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Loader from '../../utils/Loader'
import getCroppedImg from '../../utils/getCroppedImage'


function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}

function ImageCropModal({ imageSrc, onCancel, onComplete, open, aspect = 1 }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [loading, setLoading] = useState(false)

    const onCropComplete = useCallback((_, croppedAreaPixelsVal) => {
        setCroppedAreaPixels(croppedAreaPixelsVal)
    }, [])

    const handleCrop = async () => {
        setLoading(true)
        const croppedBase64 = await getCroppedImg(imageSrc, croppedAreaPixels)
        const file = dataURLtoFile(croppedBase64, open)
        onComplete(file)
        setLoading(false)
    }

    if (!open) return null

    return (
        <div className="cropModalOverlay">
            <div className="cropModalContent">
                <div className="cropHeader">Adjust Your Image</div>
                <div className="cropContainer">
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
                <div className="cropActions">
                    <button className="cancelButton" onClick={onCancel} disabled={loading}>Cancel</button>
                    <button className="cropButton" onClick={handleCrop} disabled={loading}>Done</button>
                </div>
            </div>
            {loading && <Loader type="checkmate" />}
        </div>
    )
}

export default ImageCropModal