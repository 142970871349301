import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { SnackbarProvider } from "notistack"

import App from "./App"
import "./styles/utils.css"
import "./styles/buyer.css"
import "./styles/seller.css"
import "./styles/common.css"
import "./styles/admin.css"
import "./styles/responsive.css"
import { AuthProvider } from "./utils/AuthContext"
import { NotificationsProvider } from "./utils/NotificationsContext"

ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter>
        <SnackbarProvider>
            <AuthProvider>
                <NotificationsProvider>
                    <App />
                </NotificationsProvider>
            </AuthProvider>
        </SnackbarProvider>
    </BrowserRouter>
)