import React, { useContext } from 'react'

import SampleProvisions from '../../components/buyer/SampleProvisions';
import { AuthContext } from "../../utils/AuthContext";
import FAQ from './FAQ';
import JoinOrSellerBanner from './JoinOrSellerBanner';
import SubscribeBanner from './SubscribeBanner';

function Provisions({ selectedType }) {

  const { isLogin } = useContext(AuthContext);

  return (
    <div className="homeProvisionsDiv">
      <section className='section'>

        <JoinOrSellerBanner />

        <div className="homeProvisionsContainer">
          {(selectedType === "All" || selectedType === "Products") && <SampleProvisions pre="top" selectedType="Products" />}
          {(selectedType === "All" || selectedType === "Services") && <SampleProvisions pre="top" selectedType="Services" />}
        </div>

        <SubscribeBanner />

        <div className="homeProvisionsContainer">
          {isLogin && (selectedType === "All" || selectedType === "Products") && <SampleProvisions pre="recents" selectedType="Products" />}
          {isLogin && (selectedType === "All" || selectedType === "Services") && <SampleProvisions pre="recents" selectedType="Services" />}
        </div>

        {isLogin && <FAQ />}

        <div className="homeProvisionsContainer">
          {(selectedType === "All" || selectedType === "Products") && <SampleProvisions pre="discounted" selectedType="Products" />}
          {(selectedType === "All" || selectedType === "Services") && <SampleProvisions pre="discounted" selectedType="Services" />}
        </div>

        {!isLogin && <FAQ isLogin={isLogin} />}

      </section>
    </div>
  )
}

export default Provisions