// export const hostNameFront = "http://localhost:3000";
// export const hostNameFront = "https://faithzy.onrender.com";
export const hostNameFront = "https://faithzy.com";

// export const hostNameBack = "http://localhost:5000";
// export const hostNameBack = "https://faithzy-backend.onrender.com";
export const hostNameBack = "https://api.faithzy.com";


export const states = [
    { name: "Alberta", tax: 5 },
    { name: "British Columbia", tax: 12 },
    { name: "Manitoba", tax: 12 },
    { name: "New Brunswick", tax: 15 },
    { name: "Newfoundland and Labrador", tax: 15 },
    { name: "Northwest Territories", tax: 5 },
    { name: "Nova Scotia", tax: 15 },
    { name: "Nunavut", tax: 5 },
    { name: "Ontario", tax: 13 },
    { name: "Quebec", tax: 15 },
    { name: "Prince Edward Island", tax: 15 },
    { name: "Saskatchewan", tax: 11 },
    { name: "Yukon", tax: 5 }, 
]; 

const crrDate = new Date();
crrDate.setDate(crrDate.getDate());
export const today = crrDate.toISOString().split('T')[0];
crrDate.setDate(crrDate.getDate() + 1);
export const tommorrow = crrDate.toISOString().split('T')[0];