// components/buyer/FAQ.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { hostNameBack } from '../../utils/constants';
import { FaPlus, FaMinus } from 'react-icons/fa';

function FAQ({isLogin}) {
    const [faqs, setFaqs] = useState([]);
    const [openIndex, setOpenIndex] = useState(null);

    useEffect(() => {
        fetchFAQs();
    }, []);

    const fetchFAQs = async () => {
        try {
            const { data } = await axios.get(`${hostNameBack}/api/v1/faq/all`);
            if (data.success) {
                setFaqs(data.faqs);
            }
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        }
    };

    const toggleFAQ = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    if (faqs.length === 0) return null;

    return (
        <div className="faqDiv" style={!isLogin? {marginBottom: "80px"} : {}}>
            <div className="faqSection">
                <h2 className="primaryHeading">
                    Frequently Asked <span>Questions</span>
                </h2>
                <div className="faqContent">
                    {faqs.map((faq, index) => (
                        <div key={faq._id} className="faqItem">
                            <div className="faqQuestion" onClick={() => toggleFAQ(index)}>
                                <p>{faq.question}</p>
                                {openIndex === index ? (
                                    <FaMinus className="faqToggleIcon" />
                                ) : (
                                    <FaPlus className="faqToggleIcon" />
                                )}
                            </div>
                            <div className={`faqAnswer ${openIndex === index ? 'open' : ''}`}>
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FAQ;
