// CategoryFilter.js
import React from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { hostNameBack } from "../../../utils/constants";
import Loader from "../../../utils/Loader";

function CategoryFilter({ isProduct, selectedCategories, setSelectedCategories }) {
  const [categories, setCategories] = React.useState([]);
  const [catsShown, setCatsShown] = React.useState(10);
  const [loadingCats, setLoadingCats] = React.useState(false);

  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoadingCats(true);
        const response = await axios.get(`${hostNameBack}/api/v1/categories/${isProduct ? "product" : "service"}/all`);
        if (response.data.success) {
          setCategories(response.data.categories);
        } else {
          enqueueSnackbar("Something went wrong!", { variant: "error" });
        }
      } catch (e) {
        enqueueSnackbar(e.response?.data?.error || "Something went wrong!", { variant: "error" });
      } finally {
        setLoadingCats(false);
      }
    };
    fetchCategories();
  }, [isProduct]);

  const handleCategoryChange = (catName) => {
    if (selectedCategories.includes(catName)) {
      setSelectedCategories(selectedCategories.filter(c => c !== catName));
    } else {
      setSelectedCategories([...selectedCategories, catName]);
    }
  };

  return (
    <div className="categoryFilter filter">
      <h2 className="secondaryHeading">CATEGORY</h2>
      {loadingCats ? (
        <Loader type="mini" />
      ) : (
        <>
          {categories.slice(0, catsShown).map(category => {
            const isChecked = selectedCategories.includes(category.name);
            return (
              <label
                key={category.name}
                className={`category row ${isChecked ? "active" : ""}`}
              >
                <input
                  type="checkbox"
                  name="categories"
                  checked={isChecked}
                  onChange={() => handleCategoryChange(category.name)}
                />
                {category.name}
              </label>
            );
          })}
          {categories.length > catsShown && (
            <p
              className='seeMore'
              onClick={() => setCatsShown(prev => prev + 10)}
            >
              + See More
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default CategoryFilter;
