// getCroppedImg.js

export function createImage(url) {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })
}

export default function getCroppedImg(imageSrc, pixelCrop) {
    return new Promise(async (resolve) => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const safeArea = Math.max(image.width, image.height) * 2
        canvas.width = safeArea
        canvas.height = safeArea
        const xMid = safeArea / 2 - image.width / 2
        const yMid = safeArea / 2 - image.height / 2
        ctx.drawImage(image, xMid, yMid)
        const data = ctx.getImageData(0, 0, safeArea, safeArea)
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        ctx.putImageData(
            data,
            Math.round(-xMid - pixelCrop.x),
            Math.round(-yMid - pixelCrop.y)
        )
        resolve(canvas.toDataURL('image/jpeg'))
    })
}
