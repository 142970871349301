import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";

function NotAuthorizedError({ message, actions }) {

    const navigate = useNavigate();

    return (
        <div className='notAuthorizedError'>

            <div className="message">{message}</div>
            <div className="actionBtns">
                {actions.map((action, index) => {
                    return <button className={index % 2 === 0 ? "primaryBtn" : "primaryBtn3"} key={index} onClick={() => navigate(action.to)}>{action.name} <FaArrowRight /></button>
                })}
            </div>

        </div>
    )
}

export default NotAuthorizedError