import React from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { hostNameBack } from '../../utils/constants';

function ResetPassword() {
    const [password, setPassword] = React.useState("");
    const [confirmPass, setConfirmPass] = React.useState("");
    const [passwordHidden, setPasswordHidden] = React.useState(true); // New state for toggling password visibility
    const [confirmPasswordHidden, setConfirmPasswordHidden] = React.useState(true); // New state for confirm password visibility

    const { token } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const resetPassword = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${hostNameBack}/api/v1/auth/resetPassword/${token}`, { password, confirmPass });
            enqueueSnackbar("Password reset successful", { variant: "success" });
            navigate("/login");
        } catch (e) {
            if (e.response?.data?.error)
                enqueueSnackbar(e.response?.data?.error, { variant: "error" });
            else {
                console.log(e);
                enqueueSnackbar("Can't Reset Password... Something went wrong", { variant: "error" });
            }
        }
    };

    return (
        <div className='resetPasswordDiv'>
            <Link to="/" className="faithzyLogoDiv">
                <img src="/assets/images/logo.svg" className='faithzyLogo' alt="Faithzy Logo" />
            </Link>

            <div className="resetPasswordBanner">
                <img src="/assets/images/reset.svg" alt="Reset Password Banner" />
            </div>

            <section className='section'>
                <form onSubmit={resetPassword} className='form'>
                    <h1 className='primaryHeading'>Reset <span>Password</span></h1>

                    {/* Password Field */}
                    <div className='inputDiv'>
                        <div className="passwordFieldUpper">
                            <label htmlFor="password">New Password</label>
                            <div
                                className='hidePasswordBtn'
                                onClick={() => setPasswordHidden((prev) => !prev)}
                            >
                                <i className={passwordHidden ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}></i>
                            </div>
                        </div>
                        <input
                            type={passwordHidden ? "password" : "text"}
                            name="password"
                            placeholder='Enter new password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='inputField'
                        />
                    </div>

                    {/* Confirm Password Field */}
                    <div className='inputDiv'>
                        <div className="passwordFieldUpper">
                            <label htmlFor="confirmPass">Confirm New Password</label>
                            <div
                                className='hidePasswordBtn'
                                onClick={() => setConfirmPasswordHidden((prev) => !prev)}
                            >
                                <i className={confirmPasswordHidden ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}></i>
                            </div>
                        </div>
                        <input
                            type={confirmPasswordHidden ? "password" : "text"}
                            name="confirmPass"
                            placeholder='Confirm new password'
                            value={confirmPass}
                            onChange={(e) => setConfirmPass(e.target.value)}
                            className='inputField'
                        />
                    </div>

                    <div>
                        <input type="submit" value="Reset Password" className='primaryBtn ' />
                    </div>
                </form>
            </section>
        </div>
    );
}

export default ResetPassword;
