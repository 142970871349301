import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ProductCard from '../../components/buyer/ProductCard';
import PriceFilter from '../../components/buyer/Filters/PriceFilter';
import RatingFilter from '../../components/buyer/Filters/RatingFilter';
import Pagination from '../../components/common/Pagination';
import CategoryFilter from '../../components/buyer/Filters/CategoryFilter';
import ActiveFilters from '../../components/buyer/Filters/ActiveFilters';
import { hostNameBack } from '../../utils/constants';
import Loader from '../../utils/Loader';

function Products() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const [loading, setLoading] = useState(false);

    const defaultPriceRange = { min: 0, max: 1000000 };
    const [priceRange, setPriceRange] = useState(defaultPriceRange);
    const [rating, setRating] = useState(null);
    const [page, setPage] = useState(1);

    const searchQuery = searchParams.get('search') || '';
    const categoriesParam = searchParams.getAll('categories');
    const [selectedCategories, setSelectedCategories] = useState(categoriesParam);

    useEffect(() => {
        const params = new URLSearchParams();
        if (searchQuery) {
            params.set('search', searchQuery);
        }
        if (selectedCategories.length > 0) {
            params.set('categories', selectedCategories.join(','));
        }
        if (priceRange.min !== defaultPriceRange.min || priceRange.max !== defaultPriceRange.max) {
            params.set('minPrice', priceRange.min);
            params.set('maxPrice', priceRange.max);
        }
        if (rating) {
            params.set('rating', rating);
        }
        if (page !== 1) {
            params.set('page', page);
        }
        navigate(`/products?${params.toString()}`, { replace: true });
    }, [searchQuery, selectedCategories, priceRange, rating, page, navigate]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${hostNameBack}/api/v1/products/results/all/`, {
                    params: {
                        search: searchQuery,
                        categories: selectedCategories,
                        minPrice: priceRange.min,
                        maxPrice: priceRange.max,
                        rating,
                        page,
                    },
                });
                if (response.data.success) {
                    setProducts(response.data.products);
                    setTotalPages(response.data.totalPages);
                    setTotalProducts(response.data.totalProducts);
                } else {
                    enqueueSnackbar("Something went wrong!", { variant: "error" });
                }
            } catch (e) {
                enqueueSnackbar(e.response?.data?.error || "Something went wrong!", { variant: "error" });
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, [searchQuery, selectedCategories, priceRange, rating, page]);

    const productElems = products.map(product => (
        <ProductCard key={product._id} item={product} />
    ));

    return (
        <div className='productsDiv'>
            <section className="section">
                <div className="productsContent">
                    <div className="left filters">
                        <CategoryFilter
                            isProduct={true}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                        />
                        <div className="horizontalLine"></div>
                        <PriceFilter priceRange={priceRange} setPriceRange={setPriceRange} />
                        <div className="horizontalLine"></div>
                        <RatingFilter rating={rating} setRating={setRating} />
                    </div>
                    <div className="right">
                        {!(priceRange.min === defaultPriceRange.min &&
                            priceRange.max === defaultPriceRange.max &&
                            rating === null &&
                            selectedCategories.length === 0
                        ) && (
                                <>
                                    <ActiveFilters
                                        priceRange={priceRange}
                                        setPriceRange={setPriceRange}
                                        defaultPriceRange={defaultPriceRange}
                                        rating={rating}
                                        setRating={setRating}
                                        selectedCategories={selectedCategories}
                                        setSelectedCategories={setSelectedCategories}
                                    />
                                    <div className="horizontalLine"></div>
                                </>
                            )}
                        <p className='resultsFound'>
                            <span>{totalProducts}</span> results found
                        </p>
                        <div className="horizontalLine"></div>
                        {loading ? (
                            <Loader type="simple" />
                        ) : (
                            <div className="products">
                                {productElems.length > 0 ? productElems : "Nothing to show here!"}
                            </div>
                        )}
                        {(!loading && totalProducts > 0) && (
                            <Pagination pages={totalPages} crrPage={page} setCrrPage={setPage} />
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Products;
