import React, { useContext } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function JoinOrSellerBanner() {
    const { isLogin, user } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!isLogin) {
        return (
            <div className="joinSellerBanner">
                <h2 className="primaryHeading">Join us to unlock amazing possibilities!</h2>
                <div className="joinSellerBannerActions">
                    <button className="primaryBtn" onClick={()=> navigate("/login")}>Login <FaArrowRight /></button>
                    <button className="primaryBtn3" onClick={()=> navigate("/register")}>Register <FaArrowRight /></button>
                </div>
            </div>
        );
    }

    else if (isLogin && user && !user.role === "seller") {
        return (
            <div className="joinSellerBanner">
                <h2 className="primaryHeading">Expand your reach by becoming a Seller!</h2>
                <p className="secondaryHeading">Get started on selling your products and services to a wider audience.</p>
                <div className="joinSellerBannerActions">
                    <button className="primaryBtn" onClick={()=> navigate("/seller/becomeaseller")}>Register as Seller <FaArrowRight /></button>
                </div>
            </div>
        );
    }

    return null;
}

export default JoinOrSellerBanner;
