import React, { useState } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { hostNameBack } from '../../utils/constants';

function SubscribeBanner() {
    const [email, setEmail] = useState('');

    const handleSubscribe = async () => {
        try {
            if (!email) {
                enqueueSnackbar('Please enter an email', { variant: 'warning' });
                return;
            }
            const { data } = await axios.post(`${hostNameBack}/api/v1/settings/admin/subscribe`, { email });
            enqueueSnackbar(data.message || 'Subscription successful!', { variant: 'success' });
            setEmail('');
        } catch (err) {
            enqueueSnackbar(err?.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        }
    };

    return (
        <div className="subscribeBannerDiv">
            <div className="subscribeContent">
                <h2 className="primaryHeading">
                    Stay in <span>Touch</span>
                </h2>
                <h2 className="secondaryHeading">
                    Enter your email to receive the latest updates and offers.
                </h2>
                <div className="subscribeForm">
                    <input
                        className="inputField"
                        type="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button className="secondaryBtn" onClick={handleSubscribe}>
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubscribeBanner;
