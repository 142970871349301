import React, { useState, useContext, useEffect, useMemo } from 'react';
import axios from 'axios';
import { AuthContext } from "../../utils/AuthContext";
import { enqueueSnackbar } from 'notistack';
import { BsStripe } from "react-icons/bs";
import { formatDate } from '../../utils/utilFuncs';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { SiWise } from "react-icons/si";
import { FaPaypal } from "react-icons/fa";
import { Switch } from '@mui/material';
import { hostNameBack } from '../../utils/constants';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const SellerEarnings = () => {
    const [loading, setLoading] = useState(false);
    const [showWithdrawalModel, setShowWithdrawalModel] = useState(false);
    const [showAddWiseModel, setShowAddWiseModel] = useState(false);
    const [showAddPaypalModel, setShowAddPaypalModel] = useState(false);
    const [wiseAccountId, setWiseAccountId] = useState("");
    const [paypalEmail, setPaypalEmail] = useState("");
    const [stripeActive, setStripeActive] = useState(false);
    const [wiseActive, setWiseActive] = useState(false);
    const [paypalActive, setPaypalActive] = useState(false);
    const { user, fetchUserData } = useContext(AuthContext);

    const [history, setHistory] = useState([]);
    const [earnings, setEarnings] = useState({
        availableBalance: 0,
        totalEarnings: 0,
        paidBalance: 0,
        requestedForWithdrawal: 0,
        productsSold: 0,
        servicesDone: 0
    });

    const [filter, setFilter] = useState("7d");
    const [customStartDate, setCustomStartDate] = useState("");
    const [customEndDate, setCustomEndDate] = useState("");
    const token = localStorage.getItem("token");
    const today = new Date().toISOString().split("T")[0];

    useEffect(() => {
        if (localStorage.getItem('requestSubmitted')) {
            enqueueSnackbar("Withdrawal request submitted!", { variant: "success" });
            localStorage.removeItem('requestSubmitted');
        }
    }, []);

    useEffect(() => {
        if (!user) return;
        setStripeActive(user?.sellerId?.activePaymentMethod === "Stripe");
        setWiseActive(user?.sellerId?.activePaymentMethod === "Wise");
        setPaypalActive(user?.sellerId?.activePaymentMethod === "Paypal");
        if (user?.sellerId?.wiseAccountId) setWiseAccountId(user?.sellerId?.wiseAccountId);
        if (user?.sellerId?.paypalEmail) setPaypalEmail(user?.sellerId?.paypalEmail);

        axios.get(`${hostNameBack}/api/v1/payments/seller/${user?.sellerId?._id}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                if (response.data.success) setHistory(response.data.history);
            })
            .catch(() => enqueueSnackbar("Something went wrong with payments", { variant: 'error' }));

        axios.get(`${hostNameBack}/api/v1/payments/seller/${user?.sellerId?._id}/earnings`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                if (response.data.success) setEarnings(response.data.earnings);
            })
            .catch(() => enqueueSnackbar("Something went wrong with earnings", { variant: 'error' }));
    }, [user, token]);

    function getDateXDaysAgo(days) {
        const d = new Date();
        d.setDate(d.getDate() - days);
        return d;
    }

    const filteredHistory = useMemo(() => {
        if (!history.length) return [];
        if (filter === "lifetime") return history;
        if (filter === "custom") {
            if (!customStartDate || !customEndDate) return history;
            const start = new Date(customStartDate);
            const end = new Date(customEndDate);
            return history.filter((h) => {
                const itemDate = new Date(h.date);
                return itemDate >= start && itemDate <= new Date(end.getTime() + 86399999);
            });
        }
        let rangeDays = 7;
        if (filter === "30d") rangeDays = 30;
        else if (filter === "90d") rangeDays = 90;
        const cutoff = getDateXDaysAgo(rangeDays);
        return history.filter(h => new Date(h.date) >= cutoff);
    }, [history, filter, customStartDate, customEndDate]);

    const paymentElems = filteredHistory.length > 0
        ? filteredHistory.map((item, index) => (
            <div key={index}>
                <div className="requestRow row">
                    <div className="titleField field">
                        <p className="title">{item.description}</p>
                    </div>
                    <p className="idField field">{item.orderIdCustom || "-"}</p>
                    <p className="buyerField field">{item.buyerUsername || "-"}</p>
                    <p className="typeField field">{item.itemType || "-"}</p>
                    <p className="dateField field">{formatDate(item.date)}</p>
                    <p className="methodField field">{item.paymentMethod || "-"}</p>
                    <p className="priceField field">${item.amount}</p>
                </div>
                {filteredHistory.length > 1 && filteredHistory.length - 1 !== index &&
                    <div className="horizontalLine"></div>
                }
            </div>
        ))
        : <div className="row">Nothing to show here...</div>;

    const handleGeneratePdf = () => {
        const doc = new jsPDF();
        doc.setFontSize(16);

        let rangeLabel = "Last 7 Days";
        if (filter === "30d") rangeLabel = "Last 30 Days";
        else if (filter === "90d") rangeLabel = "Last 90 Days";
        else if (filter === "lifetime") rangeLabel = "Lifetime";
        else if (filter === "custom" && customStartDate && customEndDate) {
            rangeLabel = `Custom: ${customStartDate} to ${customEndDate}`;
        }

        doc.text(`Payment History - ${rangeLabel}`, 14, 20);

        const columns = [
            "Activity",
            "Order ID",
            "Buyer",
            "Order Type",
            "Date",
            "Payment Method",
            "Amount"
        ];
        const rows = filteredHistory.map(item => ([
            item.description,
            item.orderIdCustom || "-",
            item.buyerUsername || "-",
            item.itemType || "-",
            formatDate(item.date),
            item.paymentMethod || "-",
            `$${item.amount}`
        ]));

        autoTable(doc, {
            startY: 30,
            head: [columns],
            body: rows
        });

        doc.save("payments-history.pdf");
    };

    const connectStripe = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${hostNameBack}/api/v1/payments/seller/connect-stripe`,
                { sellerId: user?.sellerId?._id },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            window.location.href = response.data.url;
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setLoading(false);
        }
    };

    const requestedWithdrawal = async () => {
        setShowWithdrawalModel(false);
        try {
            const response = await axios.post(
                `${hostNameBack}/api/v1/payments/seller/request-withdrawal`,
                {
                    userId: user?._id,
                    sellerId: user?.sellerId?._id,
                    amount: earnings.availableBalance,
                    activeMethod: stripeActive ? "Stripe" : wiseActive ? "Wise" : "Paypal"
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                localStorage.setItem('requestSubmitted', 'true');
                window.location.reload();
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
        }
    };

    const addWise = async () => {
        if (!wiseAccountId) {
            enqueueSnackbar("Please enter Account ID!", { variant: "error" });
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${hostNameBack}/api/v1/payments/seller/add-wise`,
                { sellerId: user?.sellerId?._id, wiseAccountId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                enqueueSnackbar("Wise account added successfully!", { variant: "success" });
                setShowAddWiseModel(false);
                await fetchUserData();
                setWiseActive(true);
                setStripeActive(false);
            }
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setLoading(false);
        }
    };

    const addPaypal = async (e) => {
        e.preventDefault();
        if (!paypalEmail) {
            enqueueSnackbar("Please enter Paypal Email!", { variant: "error" });
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${hostNameBack}/api/v1/payments/seller/add-paypal`,
                { sellerId: user?.sellerId?._id, paypalEmail },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                enqueueSnackbar("Paypal account added successfully!", { variant: "success" });
                setShowAddPaypalModel(false);
                await fetchUserData();
                setPaypalActive(true);
                setStripeActive(false);
                setWiseActive(false);
            }
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setLoading(false);
        }
    };

    const handleToggle = (method) => {
        if (window.confirm(`Are you sure you want to turn off ${method}?`)) {
            updateActivePaymentMethod(null);
        }
    };

    const handleStripeToggle = () => {
        if (stripeActive) handleToggle("Stripe");
        else updateActivePaymentMethod("Stripe");
    };
    const handleWiseToggle = () => {
        if (wiseActive) handleToggle("Wise");
        else updateActivePaymentMethod("Wise");
    };
    const handlePaypalToggle = () => {
        if (paypalActive) handleToggle("Paypal");
        else updateActivePaymentMethod("Paypal");
    };
    const updateActivePaymentMethod = async (method) => {
        try {
            const response = await axios.post(
                `${hostNameBack}/api/v1/payments/seller/update-active-payment-method`,
                { sellerId: user?.sellerId?._id, activePaymentMethod: method },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.success) {
                enqueueSnackbar(`Active payment method updated!`, { variant: "success" });
                setStripeActive(method === "Stripe");
                setWiseActive(method === "Wise");
                setPaypalActive(method === "Paypal");
                await fetchUserData();
            }
        } catch (error) {
            enqueueSnackbar("Failed to update active payment method", { variant: "error" });
        }
    };

    return (
        <div className='earningsDiv'>
            <section className='section'>
                <div className="earningsContent">
                    <div className='sellerPaymentMethodsDiv'>
                        <h2 className='secondaryHeading'><span>Payment</span> Methods</h2>
                        <div className="paymentMethods">
                            <div className="method"><BsStripe className='methodLogo' /><span>Stripe</span></div>
                            <div className='buttonsDiv'>
                                <Switch
                                    checked={stripeActive}
                                    onChange={handleStripeToggle}
                                    color="primary"
                                    disabled={!user?.sellerId?.stripeAccountId}
                                />
                                <button
                                    className='secondaryBtn'
                                    onClick={connectStripe}
                                    disabled={loading || user?.sellerId?.stripeAccountId}
                                >
                                    {user?.sellerId?.stripeAccountId ? "Connected" : loading ? "Loading..." : "Connect"}
                                </button>
                            </div>
                        </div>
                        <div className="paymentMethods">
                            <div className="method"><SiWise className='methodLogo' /><span>Wise</span></div>
                            <div className='buttonsDiv'>
                                <Switch
                                    checked={wiseActive}
                                    onChange={handleWiseToggle}
                                    color="primary"
                                    disabled={!user?.sellerId?.wiseAccountId}
                                />
                                <button
                                    className="secondaryBtn"
                                    onClick={() => setShowAddWiseModel(true)}
                                    disabled={loading}
                                >
                                    {user?.sellerId?.wiseAccountId ? "Edit Details" : "Add Wise"}
                                </button>
                            </div>
                        </div>
                        <div className="paymentMethods">
                            <div className="method"><FaPaypal className='methodLogo' /><span>Paypal</span></div>
                            <div className='buttonsDiv'>
                                <Switch
                                    checked={paypalActive}
                                    onChange={handlePaypalToggle}
                                    color="primary"
                                    disabled={!user?.sellerId?.paypalEmail}
                                />
                                <button
                                    className="secondaryBtn"
                                    onClick={() => setShowAddPaypalModel(true)}
                                    disabled={loading}
                                >
                                    {user?.sellerId?.paypalEmail ? "Edit Details" : "Add Paypal"}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="earningsOverview">
                        <div className="overviewBox">
                            <h2 className="secondaryHeading">Available Balance</h2>
                            <div className="value">${earnings.availableBalance.toFixed(2)}</div>
                            <button
                                className="secondaryBtn"
                                disabled={Math.floor(earnings.availableBalance) === 0}
                                onClick={() => setShowWithdrawalModel(true)}
                            >
                                Withdraw
                            </button>
                        </div>
                        <div className="overviewBox">
                            <h2 className="secondaryHeading">Total Earnings</h2>
                            <div className="value">${earnings.totalEarnings.toFixed(2)}</div>
                        </div>
                        <div className="overviewBox">
                            <h2 className="secondaryHeading">Paid Balance</h2>
                            <div className="value">${earnings.paidBalance.toFixed(2)}</div>
                        </div>
                        <div className="overviewBox">
                            <h2 className="secondaryHeading">Requested for Withdrawal</h2>
                            <div className="value">${earnings.requestedForWithdrawal.toFixed(2)}</div>
                        </div>
                        <div className="overviewBox">
                            <h2 className="secondaryHeading">Products Sold</h2>
                            <div className="value">
                                {(earnings.productsSold < 10 && "0") + earnings.productsSold}
                            </div>
                        </div>
                        <div className="overviewBox">
                            <h2 className="secondaryHeading">Services Done</h2>
                            <div className="value">
                                {(earnings.servicesDone < 10 && "0") + earnings.servicesDone}
                            </div>
                        </div>
                    </div>

                    <div className="tableDiv paymentHistoryTableDiv">
                        <section className="section">
                            <div className="tableContent">
                                <div className="upper">
                                    <h2 className="secondaryHeading">Payments <span>History</span></h2>
                                    <div className="filterDiv">
                                        {filter === 'custom' && (
                                            <div className="customDateRange">
                                                <label>Start Date:</label>
                                                <input
                                                    type="date"
                                                    value={customStartDate}
                                                    onChange={e => setCustomStartDate(e.target.value)}
                                                    max={today}
                                                />
                                                <label>End Date:</label>
                                                <input
                                                    type="date"
                                                    value={customEndDate}
                                                    onChange={e => setCustomEndDate(e.target.value)}
                                                    max={today}
                                                />
                                            </div>
                                        )}
                                        <select
                                            onChange={e => setFilter(e.target.value)}
                                            className='dropdownPlus'
                                            value={filter}
                                        >
                                            <option value="7d">Last 7 days</option>
                                            <option value="30d">Last 30 days</option>
                                            <option value="90d">Last 90 days</option>
                                            <option value="lifetime">Lifetime</option>
                                            <option value="custom">Custom</option>
                                        </select>
                                        <button
                                            className="secondaryBtn generateReportBtn"
                                            onClick={handleGeneratePdf}
                                            disabled={!filteredHistory.length}
                                        >
                                            Generate PDF
                                        </button>
                                    </div>
                                </div>
                                <div className="header">
                                    <p className="title">Activity</p>
                                    <p>Order ID</p>
                                    <p>Buyer</p>
                                    <p>Order Type</p>
                                    <p>Date</p>
                                    <p>Payment Method</p>
                                    <p>Amount</p>
                                </div>
                                <div className="rows">{paymentElems}</div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            {showWithdrawalModel && (
                <div className="popupDiv">
                    <div className="popupContent">
                        <div className="form">
                            <h2 className="secondaryHeading">
                                <span>Withdraw</span> Payment
                            </h2>
                            <div className="horizontalLine"></div>
                            <div className="description">
                                {(stripeActive && user?.sellerId?.stripeAccountId) ? earnings.availableBalance >= 30 ?
                                    <div className='form'>
                                        <div>Active Method: <span className='fw500'>Stripe</span></div>
                                        <p>You are about to withdraw ${earnings.availableBalance}.</p>
                                    </div> :
                                    <p>Your available balance is less than $30.</p> :
                                    (wiseActive && user?.sellerId?.wiseAccountId) ? earnings.availableBalance >= 30 ?
                                        <div className='form'>
                                            <div>Active Method: <span className='fw500'>Wise</span></div>
                                            <p>You are about to withdraw ${earnings.availableBalance}.</p>
                                        </div> :
                                        <p>Your available balance is less than $30.</p> :
                                        (paypalActive && user?.sellerId?.paypalEmail) ? earnings.availableBalance >= 30 ?
                                            <div className='form'>
                                                <div>Active Method: <span className='fw500'>Paypal</span></div>
                                                <p>You are about to withdraw ${earnings.availableBalance}.</p>
                                            </div> :
                                            <p>Your available balance is less than $30.</p> :
                                            <p>No active payment method.</p>
                                }
                            </div>
                            <div className="buttonsDiv">
                                <button
                                    className="primaryBtn"
                                    type="button"
                                    disabled={
                                        (!user?.sellerId?.stripeAccountId &&
                                            !user?.sellerId?.wiseAccountId &&
                                            !user?.sellerId?.paypalEmail) ||
                                        (earnings.availableBalance < 30) ||
                                        (!stripeActive && !wiseActive && !paypalActive)
                                    }
                                    onClick={requestedWithdrawal}
                                >
                                    Withdraw ${earnings.availableBalance}
                                </button>
                                <button
                                    className="secondaryBtn"
                                    type="button"
                                    onClick={() => setShowWithdrawalModel(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="popupCloseBtn">
                        <IoIosCloseCircleOutline
                            className="icon"
                            onClick={() => setShowWithdrawalModel(false)}
                        />
                    </div>
                </div>
            )}

            {showAddWiseModel && (
                <div className="popupDiv">
                    <div className="popupContent">
                        <div className="form">
                            <h2 className="secondaryHeading">Add <span>Wise</span></h2>
                            <div className="horizontalLine"></div>
                            <div className="inputDiv">
                                <label>Wise Account ID</label>
                                <input
                                    type="text"
                                    value={wiseAccountId}
                                    onChange={(e) => setWiseAccountId(e.target.value)}
                                    className='inputField'
                                    placeholder="Enter your Wise Account ID"
                                />
                            </div>
                            <p>Make sure you enter correct Account ID, otherwise you may not receive your funds!</p>
                            <div className="buttonsDiv">
                                <button className="primaryBtn" type="button" onClick={addWise}>Save</button>
                                <button className="secondaryBtn" type="button" onClick={() => setShowAddWiseModel(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                    <div className="popupCloseBtn">
                        <IoIosCloseCircleOutline className="icon" onClick={() => setShowAddWiseModel(false)} />
                    </div>
                </div>
            )}

            {showAddPaypalModel && (
                <div className="popupDiv">
                    <div className="popupContent">
                        <form className="form" onSubmit={addPaypal}>
                            <h2 className="secondaryHeading">Add <span>Paypal</span></h2>
                            <div className="horizontalLine"></div>
                            <div className="inputDiv">
                                <label>Paypal Email</label>
                                <input
                                    type="email"
                                    value={paypalEmail}
                                    onChange={(e) => setPaypalEmail(e.target.value)}
                                    className='inputField'
                                    placeholder="Enter your Paypal Email"
                                    required
                                />
                            </div>
                            <p>Make sure you enter correct Paypal Email.</p>
                            <div className="buttonsDiv">
                                <button className="primaryBtn" type="submit">Save</button>
                                <button
                                    className="secondaryBtn"
                                    type="button"
                                    onClick={() => setShowAddPaypalModel(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="popupCloseBtn">
                        <IoIosCloseCircleOutline
                            className="icon"
                            onClick={() => setShowAddPaypalModel(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SellerEarnings;
