import React, { useState, useCallback } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import Cropper from 'react-easy-crop';
import Loader from '../../utils/Loader';
import getCroppedImg from '../../utils/getCroppedImage';

function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function parseAspect(dimension) {
  if (dimension === '1:1') return 1;
  if (dimension === 'fiverrGig') return 550 / 370;
  return 1;
}

function Gallery({ images, setImages, dimension = '1:1', setDetails, isCreateServiceGallery, isDeliverOrderGallery }) {
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [filesToCrop, setFilesToCrop] = useState([]);
  const [tempImage, setTempImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const aspect = parseAspect(dimension);

  const onCropComplete = useCallback((_, croppedAreaPixelsVal) => {
    setCroppedAreaPixels(croppedAreaPixelsVal);
  }, []);

  const handleGalleryUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    if (!newFiles.length) return;

    const toCrop = [];
    const valid = [];
    let pendingChecks = newFiles.length;

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const w = img.width;
          const h = img.height;
          const ratio = w / h;
          const targetRatio = aspect;

          if (Math.abs(ratio - targetRatio) > 0.01) {
            toCrop.push({ file, dataUrl: reader.result });
          } else {
            const croppedFile = dataURLtoFile(reader.result, file.name);
            valid.push(croppedFile);
          }

          pendingChecks--;
          if (!pendingChecks) {

            if (valid.length) setImages((prev) => [...prev, ...valid]);

            if (setDetails) {
              if (isCreateServiceGallery)
                setDetails(prev => ({
                  ...prev,
                  serviceImages: [...prev.serviceImages, ...valid]
                }));
              else
                setDetails(prev => ({
                  ...prev,
                  productImages: [...prev.productImages, ...valid]
                }));
            }

            if (toCrop.length) {
              setFilesToCrop((prev) => [...prev, ...toCrop]);
              setTempImage(toCrop[0].dataUrl);
              setCropModalOpen(true);
            }
          }
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    });
    e.target.value = null;
  };

  const handleCropDone = async () => {
    setLoading(true);

    try {
      const croppedBase64 = await getCroppedImg(tempImage, croppedAreaPixels);
      const fileName = filesToCrop[0]?.file?.name;
      if (!fileName) throw new Error('Invalid file data.');

      const croppedFile = dataURLtoFile(croppedBase64, fileName);
      setImages((prev) => [...prev, croppedFile]);

      if (setDetails) {
        if (isCreateServiceGallery)
          setDetails(prev => ({
            ...prev,
            serviceImages: [...prev.serviceImages, croppedFile]
          }));
        else
          setDetails(prev => ({
            ...prev,
            productImages: [...prev.productImages, croppedFile]
          }));
      }

      const updatedFilesToCrop = filesToCrop.slice(1); // Remove the processed file
      setFilesToCrop(updatedFilesToCrop);

      if (updatedFilesToCrop.length > 0) {
        setTempImage(updatedFilesToCrop[0].dataUrl);
      } else {
        setCropModalOpen(false);
      }
    } catch (error) {
      console.error('Error cropping image:', error);
    } finally {
      setLoading(false);
    }
  };

  const onCropButtonClick = async () => {
    await handleCropDone();
  };

  const onCancelCrop = () => {
    setCropModalOpen(false);
    setFilesToCrop([]);
    setLoading(false);
  };

  const removeImage = (removeIndex) => {
    setImages((prevImages) => prevImages.filter((_, idx) => idx !== removeIndex));

    if (setDetails) {
      if (isCreateServiceGallery)
        setDetails(prev => ({
          ...prev,
          serviceImages: [...prev.serviceImages.filter((_, idx) => idx !== removeIndex)]
        }));
      else
        setDetails(prev => ({
          ...prev,
          productImages: [...prev.productImages.filter((_, idx) => idx !== removeIndex)]
        }));
    }

  };

  return (
    <>
      <div className="galleryDiv">
        <div className="galleryComponentContent">
          <div className="galleryUpper">
            <label>
              Gallery{isDeliverOrderGallery && ' - Proof of Work'}
              <span
                className="imagesLimit"
                style={images.length > 5 ? { color: 'var(--warning)' } : { color: 'var(--darkGray)' }}
              >
                ({images.length}/5)
              </span>
              <span> *</span>
            </label>
            {images.length > 0 && (
              <div className="clearBtn" onClick={() => {
                setImages([]);
                if (setDetails) {
                  if (isCreateServiceGallery)
                    setDetails(prev => ({ ...prev, serviceImages: [] }));
                  else
                    setDetails(prev => ({ ...prev, productImages: [prev.productImages[0]] }))
                }
              }}>
                Clear
              </div>
            )}
          </div>

          <div className="galleryContent">
            <div className="galleryImagesWrapper">
              {images.map((image, index) => (
                <div className="uploadedImageCard" key={index}>
                  <img
                    src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                    alt="Gallery"
                    className="galleryImage"
                  />
                  <button
                    type="button"
                    className="removeImageBtn"
                    onClick={() => removeImage(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}

              {images.length < 5 && (
                <label htmlFor="galleryUpload" className="addMoreLabel">
                  <FaPlusCircle className="uploadIcon" />
                </label>
              )}
            </div>

            <input
              type="file"
              id="galleryUpload"
              className="inputField"
              onChange={handleGalleryUpload}
              multiple
            />
          </div>
        </div>
      </div>

      {cropModalOpen && (
        <div className="cropModalOverlay">
          <div className="cropModalContent">
            <div className="cropHeader">Crop Image</div>
            <div className="cropContainer">
              <Cropper
                image={tempImage}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="cropActions">
              <button
                className="cancelButton"
                onClick={onCancelCrop}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="cropButton"
                onClick={onCropButtonClick}
                disabled={loading}
              >
                Done
              </button>
            </div>
          </div>
          {loading && <Loader type="checkmate" />}
        </div>
      )}
    </>
  );
}

export default Gallery;