import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { hostNameBack } from './constants';

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [isBuyerServiceOrderUnread, setIsBuyerServiceOrderUnread] = useState(false);
    const [isSellerServiceOrderUnread, setIsSellerServiceOrderUnread] = useState(false);
    const [isBuyerProductOrderUnread, setIsBuyerProductOrderUnread] = useState(false);
    const [isSellerProductOrderUnread, setIsSellerProductOrderUnread] = useState(false);
    const { isLogin, user, isAdminLogin } = useContext(AuthContext);

    const checkUnreadStatus = async () => {
        if (!isLogin || !user || isAdminLogin) return;

        const token = localStorage.getItem('token');
        if (!token) return;

        try {
            const buyerProductOrders = await axios.get(`${hostNameBack}/api/v1/orders/buyer/product/all`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const sellerProductOrders = await axios.get(`${hostNameBack}/api/v1/orders/seller/product/all`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const buyerServiceOrders = await axios.get(`${hostNameBack}/api/v1/orders/buyer/service/all`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const sellerServiceOrders = await axios.get(`${hostNameBack}/api/v1/orders/seller/service/all`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const hasUnreadBuyerProductOrders = buyerProductOrders.data.orders.some(order =>
                order.products.some(product => product.isUnreadByBuyer)
            )
            const hasUnreadSellerProductOrders = sellerProductOrders.data.orders.some(order =>
                order.products.some(product => product.isUnreadBySeller)
            );

            const hasUnreadBuyerServiceOrders = buyerServiceOrders.data.orders.some(order =>
                order.isUnreadByBuyer
            )
            const hasUnreadSellerServiceOrders = sellerServiceOrders.data.orders.some(order =>
                order.isUnreadBySeller
            );

            setIsBuyerProductOrderUnread(hasUnreadBuyerProductOrders);
            setIsSellerProductOrderUnread(hasUnreadSellerProductOrders);
            setIsBuyerServiceOrderUnread(hasUnreadBuyerServiceOrders);
            setIsSellerServiceOrderUnread(hasUnreadSellerServiceOrders);

        } catch (error) {
            console.error('Error checking unread status:', error);
        }
    };

    useEffect(() => {
        checkUnreadStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, user, isAdminLogin]);

    const refreshNotifications = () => {
        checkUnreadStatus();
    };

    return (
        <NotificationsContext.Provider
            value={{
                isBuyerServiceOrderUnread,
                isSellerServiceOrderUnread,
                isBuyerProductOrderUnread,
                isSellerProductOrderUnread,
                refreshNotifications
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};