import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../utils/AuthContext';
import { hostNameBack } from '../../utils/constants';

const NotificationsDropdown = () => {
    const [notifications, setNotifications] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { isLogin } = useContext(AuthContext);

    const fetchNotifications = async () => {
        if (!isLogin) return;

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${hostNameBack}/api/v1/notifications/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setNotifications(response.data);
        } catch (error) {
            console.error('Failed to fetch notifications', error);
        }
    };

    const markNotificationsAsRead = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${hostNameBack}/api/v1/notifications/read`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            fetchNotifications();
        } catch (error) {
            console.error('Failed to mark notifications as read', error);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            markNotificationsAsRead();
        }
    };

    useEffect(() => {
        if (isLogin) {
            fetchNotifications();
            const interval = setInterval(fetchNotifications, 60000); // Refresh every minute
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.notifications-container')) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);

    }, [isOpen]);

    const unreadCount = notifications.filter(n => !n.isRead).length;

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const now = new Date();
        const diff = now - date;

        const minutes = Math.floor(diff / (1000 * 60));
        if (minutes < 1) return 'just now';
        if (minutes < 60) return `${minutes} min ago`;

        const hours = Math.floor(minutes / 60);
        if (hours < 24) return `${hours} hr ago`;

        const days = Math.floor(hours / 24);
        return `${days} days ago`;
    };

    return (
        <div className="notifications-container">
            <div
                className="notifications-icon"
                onClick={toggleDropdown}
            >
                <i className="fa-regular fa-bell"></i>
                {unreadCount > 0 && (
                    <span className="notifications-badge">{unreadCount}</span>
                )}
            </div>

            {isOpen && (
                <div className="notifications-dropdown">
                    <div className="notifications-header">
                        <h3>Notifications</h3>
                    </div>
                    {notifications.length === 0 ? (
                        <div className="no-notifications">No notifications</div>
                    ) : (
                        <ul className="notifications-list">
                            {notifications.map(notification => (
                                <li
                                    key={notification._id}
                                    className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                                >
                                    <div className="notification-content">
                                        <p>{notification.content}</p>
                                        <span className="notification-time">
                                            {formatTime(notification.createdAt)}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationsDropdown;