import React, { useContext, useRef } from 'react'
import { NavLink, Link } from 'react-router-dom'

import { AuthContext } from '../../utils/AuthContext';
import { NotificationsContext } from '../../utils/NotificationsContext';
import { IoMdClose, IoMdMenu } from 'react-icons/io';
import NotificationsDropdown from '../common/NotificationsDropdown';

function SellerHeader() {

  const { logout, isTabletPro, user, isLogin, unreadCount, refreshChatUnreadCount } = useContext(AuthContext);
  let { isSellerServiceOrderUnread, isSellerProductOrderUnread } = useContext(NotificationsContext);
  const [showOptions, setShowOptions] = React.useState(false);
  const accountRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const drawerRef = useRef(null);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (isLogin && user) {
      refreshChatUnreadCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, user]);

  const handleClickOutside = (event) => {
    if (accountRef.current && !accountRef.current.contains(event.target))
      setShowOptions(false);
    if (drawerRef.current && !drawerRef.current.contains(event.target))
      setDrawerOpen(false);
  };

  const toggleMenu = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className='sellerHeaderDiv'>
      <section className="section">
        <div className="sellerHeaderContent">

          <Link to="/" className="faithzyLogoDiv">
            <img src="/assets/images/logo.svg" alt="Faithzy Logo" className='faithzyLogo' />
          </Link>
          {!isTabletPro ? <><nav>
            <ul>
              <li><NavLink to="/seller/dashboard" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>Dashboard</NavLink></li>
              <li><NavLink to="/seller/products" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>Products</NavLink></li>
              <li><NavLink to="/seller/postings" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>Services</NavLink></li>
              <li><NavLink to="/seller/tradeleads" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>TradeLead</NavLink></li>
              <li><NavLink to="/seller/upgrade" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>Upgrade</NavLink></li>
              <li><NavLink to="/seller/earnings" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>Earnings</NavLink></li>
              <li className='ordersHeader'>
                <NavLink to="/seller/orders" className={(v) => `${v.isActive ? "white" : "darkGray"}`}>Orders</NavLink>
                {(isSellerProductOrderUnread || isSellerServiceOrderUnread) && <span className="unreadBadge"></span>}
              </li>
            </ul>
          </nav>
            <div className="sellerHeaderActions">
              <div className="leftActions">
                <NotificationsDropdown />
                <Link to="/chat" className="inboxDiv">
                  <i className="fa-regular fa-envelope"></i>
                  {unreadCount > 0 && <span className="unreadCountBadge">{unreadCount}</span>}
                </Link>
              </div>
              <div className="rightActions">
                <Link to="/seller/boosts" className="rocketIconDiv"><i className="fa-solid fa-rocket"></i></Link>
                <div className="accountDiv optionsContainer" onClick={() => setShowOptions(prev => !prev)} ref={accountRef}>
                  {isLogin && user.role === "seller" ? <img src={user.sellerId.profileImage} alt="Seller Profile" className='profilePic' /> : <i className="fa-regular fa-circle-user"></i>}
                  {showOptions && (
                    <div className='optionsMenu'>
                      <Link to="/">Switch to Buying</Link>
                      <Link to="/contact">Contact Us</Link>
                      <div className="horizontalLine"></div>
                      <Link to="/settings">Settings</Link>
                      <Link onClick={logout}>Logout</Link>
                    </div>
                  )}
                </div>
              </div>
            </div></>
            :
            <div>

              {drawerOpen ? <IoMdClose size={28} className='close' onClick={toggleMenu} /> : <IoMdMenu size={28} className='open' onClick={toggleMenu} />}

              <ul className='drawerContent' ref={drawerRef} style={{ display: (drawerOpen ? 'flex' : 'none') }}>
                <li><NavLink to="/seller/dashboard" style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} onClick={toggleMenu}>Dashboard</NavLink></li>
                <li><NavLink to="/seller/products" style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} onClick={toggleMenu}>Products</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/seller/postings" onClick={toggleMenu}>Services</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/seller/tradeleads" onClick={toggleMenu}>TradeLead</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/seller/upgrade" onClick={toggleMenu}>Upgrade</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/seller/earnings" onClick={toggleMenu}>Earnings</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/seller/orders" onClick={toggleMenu}>Orders as Seller</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/contact" onClick={toggleMenu}>Contact Us</NavLink></li>
                <li><NavLink style={(v) => v.isActive ? { color: "var(--secondaryCopper)" } : { color: "var(--white)" }} to="/settings" onClick={toggleMenu}>Settings</NavLink></li>
                <li><Link to="/" onClick={() => { toggleMenu(); logout() }}>Logout</Link></li>
              </ul>

            </div>}

        </div>
      </section>
    </div>
  )
}

export default SellerHeader