import React from 'react';

function ActiveFilters({
    priceRange,
    setPriceRange,
    defaultPriceRange,
    rating,
    setRating,
    selectedCategories,
    setSelectedCategories
}) {
    const handleClearCategory = (catName) => {
        setSelectedCategories(selectedCategories.filter(c => c !== catName));
    };

    const handleClearFilter = (filterType) => {
        if (filterType === 'price') {
            setPriceRange(defaultPriceRange);
        } else if (filterType === 'rating') {
            setRating(null);
        }
    };

    return (
        <div className="activeFilters">
            <p>Active Filters: </p>

            {(priceRange.min !== defaultPriceRange.min || priceRange.max !== defaultPriceRange.max) && (
                <div className="filterTag">
                    <span>
                        ${priceRange.min}
                        {priceRange.max === defaultPriceRange.max ? ' & above' : ` - $${priceRange.max}`}
                    </span>
                    <button onClick={() => handleClearFilter('price')}>×</button>
                </div>
            )}

            {rating !== null && (
                <div className="filterTag">
                    <span>
                        {rating === 0 ? "Not Rated" : `${rating} star${rating > 1 ? 's' : ''} ${rating === 5 ? '' : '& up'}`}
                    </span>
                    <button onClick={() => handleClearFilter('rating')}>×</button>
                </div>
            )}

            {selectedCategories.map(cat => (
                <div className="filterTag" key={cat}>
                    <span>{cat}</span>
                    <button onClick={() => handleClearCategory(cat)}>×</button>
                </div>
            ))}
        </div>
    );
}

export default ActiveFilters;
