import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ServiceCard from '../../components/buyer/ServiceCard';
import PriceFilter from '../../components/buyer/Filters/PriceFilter';
import RatingFilter from '../../components/buyer/Filters/RatingFilter';
import Pagination from '../../components/common/Pagination';
import CategoryFilter from '../../components/buyer/Filters/CategoryFilter';
import ActiveFilters from '../../components/buyer/Filters/ActiveFilters';
import { hostNameBack } from '../../utils/constants';
import Loader from '../../utils/Loader';

function Services() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [services, setServices] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalServices, setTotalServices] = useState(0);
    const [loading, setLoading] = useState(false);

    const defaultPriceRange = { min: 0, max: 1000000 };
    const [priceRange, setPriceRange] = useState(defaultPriceRange);
    const [rating, setRating] = useState(null);
    const [page, setPage] = useState(1);

    const searchQuery = searchParams.get('search') || '';

    // Instead of splitting comma-separated categories, getAll returns an array
    const categoriesArray = searchParams.getAll('categories');
    const [selectedCategories, setSelectedCategories] = useState(categoriesArray);

    useEffect(() => {
        const params = new URLSearchParams();

        if (searchQuery) {
            params.set('search', searchQuery);
        }

        // Append each category as a separate query param
        selectedCategories.forEach(cat => {
            params.append('categories', cat);
        });

        if (priceRange.min !== defaultPriceRange.min || priceRange.max !== defaultPriceRange.max) {
            params.set('minPrice', priceRange.min);
            params.set('maxPrice', priceRange.max);
        }

        if (rating) {
            params.set('rating', rating);
        }

        if (page !== 1) {
            params.set('page', page);
        }

        navigate(`/services?${params.toString()}`, { replace: true });
    }, [searchQuery, selectedCategories, priceRange, rating, page, navigate]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${hostNameBack}/api/v1/services/results/all/`, {
                    params: {
                        search: searchQuery,
                        categories: selectedCategories, // Pass array
                        minPrice: priceRange.min,
                        maxPrice: priceRange.max,
                        rating,
                        page
                    },
                });
                if (response.data.success) {
                    setServices(response.data.services);
                    setTotalPages(response.data.totalPages);
                    setTotalServices(response.data.totalServices);
                } else {
                    enqueueSnackbar("Something went wrong!", { variant: "error" });
                }
            } catch (e) {
                enqueueSnackbar(e.response?.data?.error || "Something went wrong!", { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, [searchQuery, selectedCategories, priceRange, rating, page]);

    const serviceElems = services.map(service => (
        <ServiceCard key={service._id} item={service} />
    ));

    return (
        <div className='servicesDiv'>
            <section className="section">
                <div className="servicesContent">

                    <div className="left filters">
                        <CategoryFilter
                            isProduct={false}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                        />

                        <div className="horizontalLine"></div>

                        <PriceFilter priceRange={priceRange} setPriceRange={setPriceRange} />

                        <div className="horizontalLine"></div>

                        <RatingFilter rating={rating} setRating={setRating} />
                    </div>

                    <div className="right">
                        {!(
                            priceRange.min === defaultPriceRange.min &&
                            priceRange.max === defaultPriceRange.max &&
                            rating === null &&
                            selectedCategories.length === 0
                        ) && (
                                <>
                                    <ActiveFilters
                                        priceRange={priceRange}
                                        setPriceRange={setPriceRange}
                                        defaultPriceRange={defaultPriceRange}
                                        rating={rating}
                                        setRating={setRating}
                                        selectedCategories={selectedCategories}
                                        setSelectedCategories={setSelectedCategories}
                                    />
                                    <div className="horizontalLine"></div>
                                </>
                            )}

                        <p className='resultsFound'>
                            <span>{totalServices}</span> results found
                        </p>

                        <div className="horizontalLine"></div>

                        {loading ? (
                            <Loader type="simple" />
                        ) : (
                            <div className="services">
                                {serviceElems.length > 0 ? serviceElems : "Nothing to show here!"}
                            </div>
                        )}

                        {(!loading && totalServices > 0) && (
                            <Pagination pages={totalPages} crrPage={page} setCrrPage={setPage} />
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;
